import { Container, Heading, useToast } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import type { ILoginFormData } from '@stocker/ui-components/custom'
import { LoginForm } from '@stocker/ui-components/custom'
import { Seo } from '@stocker/ui-components/design-system'
import type { GetStaticProps } from 'next'
import { useRouter } from 'next/router'
import { useLoginUserMutation } from '@stocker/codegen/vendure'
import { useIntl } from 'react-intl'
import { getServerData } from '@stocker/ui-components/helpers'

const Login = () => {
  const router = useRouter()
  const { formatMessage } = useIntl()
  const toast = useToast()
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useLoginUserMutation({
    onSuccess: async (data) => {
      if (data.login.__typename === 'InvalidCredentialsError') {
        toast({
          title: formatMessage({ id: 'login--error-wrong-credentials' }),
          description: formatMessage({ id: 'login--error-wrong-credentials-entered' }),
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      } else if (data.login.__typename === 'NativeAuthStrategyError' || data.login.__typename === 'NotVerifiedError') {
        toast({
          title: formatMessage({ id: '--error-occurred' }),
          description: formatMessage({ id: '--error-occurred-try-again' }),
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      } else {
        toast({
          title: formatMessage({ id: 'login--successfull' }),
          description: formatMessage({ id: 'login--successfully-logged-in' }),
          status: 'success',
          duration: 2000,
        })

        await queryClient.invalidateQueries()
        if (router.query.redirect) {
          return await router.push(router.query.redirect as string)
        }
        await router.push('/account')
      }
    },
    onError: (error) => {
      toast({
        title: formatMessage({ id: '--error-occurred' }),
        description: formatMessage({ id: '--error-occurred-try-again' }),
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
      console.error(error)
    },
  })

  const onSubmit = (registeredUserData: ILoginFormData) => {
    const { email, password, rememberMe } = registeredUserData
    // log in
    mutate({ username: email, password, rememberMe })
  }

  return (
    <Container py="100px" minHeight="calc(100vh - 620px)">
      <Seo
        title={formatMessage({ id: 'seo--login' })}
      />
      <Heading as="h1">{formatMessage({ id: 'login--headline' })}</Heading>
      <LoginForm
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    </Container>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      ...(await getServerData()),
    },
  }
}

export default Login
